var exports = {};

function iota(n) {
  var result = new Array(n);

  for (var i = 0; i < n; ++i) {
    result[i] = i;
  }

  return result;
}

exports = iota;
export default exports;